<!-- 营销中心 平台营销工具 平台卡券管理 优惠券管理 优惠券详情 -->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageTopButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageTopButtons"
          :key="index"
          v-bind="button"
          :disabled="button.enable"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus :labelWidth="90">
        <div slot="header" class="clearfix">
          <span class="title">分组信息</span>
        </div>
        <ykc-detail-item-plus label="用户分组编码">
          <span>{{ dealData(detail.id) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="用户分组名称">
          <span>{{ dealData(detail.groupName) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="时间范围">
          <span>
            {{
              detail.startTime && detail.endTime
                ? detail.startTime.substring(0, 11) +
                  (detail.startTime ? '到' + detail.endTime.substring(0, 11) : '——')
                : '——'
            }}
          </span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="分组维度">
          <span>
            {{ dealData(detail.groupDimensionMean) }}
          </span>
        </ykc-detail-item-plus>
        <template v-if="detail.groupDimensionMean !== '批量导入'">
          <ykc-detail-item-plus label="数据维度">
            <span>{{ dealData(detail.dataDimensionMean) }}</span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="电站范围">
            <span>
              {{
                dealData(detail.stationDimensionMean) +
                (dealData(detail.stationDimensionMean)
                  ? powerStationGroupList.length > 0
                    ? ''
                    : ''
                  : '')
              }}
            </span>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus label="电站范围">
            <ykc-tree
              ref="tree1"
              v-if="powerStationGroupList.length > 0"
              :v-model="detail.powerStationGroupList"
              multiple
              filterable
              :multiple-limit="50"
              placeholder="请选择电站"
              :data="powerStationGroupList"
              :props="treeProps"
              :nodeKey="treeNodeKey"
              style="width: 500px"></ykc-tree>
          </ykc-detail-item-plus>
          <ykc-detail-item-plus :label="detail.dataDimensionMean">
            <span>
              {{
                dealData(detail.conditionMin) +
                '' +
                (dealData(detail.conditionMin)
                  ? dealData(detail.conditionMax)
                    ? '' + dealData(detail.conditionMax)
                    : ''
                  : '')
              }}
            </span>
          </ykc-detail-item-plus>
        </template>
        <ykc-detail-item-plus label="用户数">
          <span>{{ detail.userCount }}</span>
        </ykc-detail-item-plus>

        <ykc-detail-item-plus label="创建人">
          <span>{{ dealData(detail.createdName) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="创建时间">
          <span>{{ dealData(detail.createdTime) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="修改人">
          <span>{{ dealData(detail.modifiedName) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="修改时间">
          <span>{{ dealData(detail.modifiedTime) }}</span>
        </ykc-detail-item-plus>
      </ykc-detail-plus>
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        title="用户列表"
        operateFixedType="right">
        <template #headerButtons>
          <ykc-button
            v-for="(button, index) in pageButtons.filter(item => item.enabled())"
            :key="button.id || index"
            v-bind="button"
            :disabeld="true"
            @click="handlePageButtonClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          direct="flex-end"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageInfo.size"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { CustomDriverAccounts, userLabelApi } from '@/service/apis';
  import { offlineExport, dealData, code } from '@/utils';
  import YkcDialog from '@/components/ykc-ui/dialog';

  export default {
    name: 'platformCouponDetail',
    data() {
      return {
        id: '',
        treeProps: {
          label: 'name',
          children: 'stationTreeVOList',
          disabled: 'disabled',
        },
        selectIds: [],
        powerStationGroupList: [],
        treeNodeKey: 'stationId',
        detail: {
          id: null,
          groupName: '',
          startTime: '',
          endTime: '',
          groupDimension: '',
          groupDimensionMean: '',
          dataDimension: '0',
          dataDimensionMean: '',
          stationDimension: '0',
          stationDimensionMean: '',
          conditionMin: 0,
          conditionMax: 0,
          userCount: 0,
          labelStatus: '1',
          labelStatusMean: '',
          stationList: [],
          userList: null,
          createdName: null,
          createdTime: '',
          modifiedName: null,
          modifiedTime: '',
        },
        dictionary: {},
        headerInfo: {},
        //  流水类型

        pageTopButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        pageButtons: [
          {
            id: '1',
            enabled: () => code('group:user:detail:download'),
            text: '导出',
            type: 'plain',
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],

        tableColumns: [
          { label: '用户编码', prop: 'id', minWidth: '100px' },
          { label: '用户账号', prop: 'accountNo', minWidth: '100px' },
          // 1 C端司机 2客户司机 3子公司司机
          {
            label: '用户类型',
            prop: 'userType',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                const title = this.getUserType(row.userType);
                return <span title={title}>{title}</span>;
              },
            },
          },
          { label: '归属客户', prop: 'operatorName', minWidth: '100px' },
        ],

        pageInfo: {
          total: 0,
          current: 0,
          size: 10,
        },
        searchParams: {
          id: this.id, // 标签Id
        },
        showDrawer: false,
        isEdit: false,
      };
    },
    computed: {},
    created() {
      const { id } = this.$route.query;
      this.id = id;
      this.searchParams.id = id;
      this.getDetail();
      this.queryHeaderInfo();
      this.searchTableList();
    },
    methods: {
      dealData,
      /**
       * @function queryHeaderInfo
       * @desc 查询头部新消息
       * */
      queryHeaderInfo() {
        CustomDriverAccounts.detailHeaderInfo({ userId: this.id }).then(res => {
          this.headerInfo = res || {};
        });
      },
      /** 根据条件查询数据 */
      searchTableList() {
        const customDriverAccountsDetailReqParams = {
          ...this.searchParams,
          current: this.pageInfo.current,
          size: this.pageInfo.size,
        };
        userLabelApi.queryUserInfoForPage(customDriverAccountsDetailReqParams).then(response => {
          this.tableData = response?.records || [];
          this.pageInfo.total = response?.total || 0;
        });
      },
      /** 导出文件 */
      doExportFile() {
        // CustomDriverAccounts.exportOfFlowList('代理人佣金列表', this.searchParams);
        offlineExport(
          {
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'group_user_export',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 0;
        Object.assign(this.searchParams, form);
        // if (form.createTime.length > 0) {
        //   [this.searchParams.createBeginTime, this.searchParams.createEndTime] = form.createTime;
        // }
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
      getUserType(userType) {
        let result = '-';
        if (userType === '1') {
          result = 'C端用户';
        } else if (userType === '2') {
          result = '客户司机';
        } else if (userType === '3') {
          result = '子公司司机';
        }
        return result;
      },
      getDetail() {
        // 编辑回显
        if (this.id) {
          userLabelApi
            .detail({ id: this.id })
            .then(res => {
              this.detail = {
                ...res,
              };

              res.stationList.forEach(element => {
                this.selectIds.push(this.powerStationGroupList.length);
                this.powerStationGroupList.push({
                  id: this.powerStationGroupList.length,
                  name: element,
                });
              });
              console.log(JSON.stringify(this.selectIds));
              setTimeout(() => {
                this.$nextTick(() => {
                  this.$refs.tree1.setCheckedKeys(this.selectIds, true);
                });
              }, 500);
            })
            .catch(() => {});
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .content {
    // display: flex;
    .clearfix {
      height: 19px;
      display: flex;
      align-items: center;
    }
    .box-card {
      margin: 0;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      ::v-deep .el-form-item {
        display: flex;
        align-items: center;
      }
      table {
        width: calc(100% - 30px);
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        margin-left: 30px;
        margin-top: 20px;
        th {
          background-color: #f6f7f8;
          font-weight: bold;
        }
        th,
        td {
          font-size: 12px;
          text-align: left;
          padding: 0 10px;
          line-height: 40px;
          height: 40px;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          ::v-deep .el-form-item {
            margin-bottom: 0;
            input {
              width: 100px;
            }
          }
        }
      }
      .title {
        position: relative;
        &-flag {
          margin-left: 8px;
          padding: 2px 18px;
          background: rgba(77, 140, 253, 0.1);
          border-radius: 2px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #4d8cfd;
          line-height: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
</style>
